/* Login Page */
.login-page {
    position: relative;
    min-height: 100vh;
    background: linear-gradient(135deg, #1a1a2e, #16213e);
    overflow: hidden;
  }
  
  /* Animated Background */
  .animated-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #141515, #141515);
    z-index: 1;
    animation: gradientShift 10s ease infinite;
  }
  
  @keyframes gradientShift {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  /* Particle Animation */
  .animated-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 10%, transparent 10.01%);
    background-size: 20px 20px;
    animation: moveParticles 10s linear infinite;
  }
  
  @keyframes moveParticles {
    0% {
      transform: translateY(0) translateX(0);
    }
    50% {
      transform: translateY(-50px) translateX(-50px);
    }
    100% {
      transform: translateY(0) translateX(0);
    }
  }
  
  /* Login Container */
  .login-container {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* min-height: 100vh; */
    color: #fff;
  }
  
  /* Form Wrapper */
  .form-wrapper {
    background: rgba(255, 255, 255, 0.1);
    padding: 2rem;
    border-radius: 15px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    animation: fadeIn 1.5s ease-in-out, float 6s ease-in-out infinite;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  /* Input Fields */
  .form-login input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    font-size: 16px;
    transition: all 0.3s ease;
  }
  
  .form-login input:focus {
    background: rgba(255, 255, 255, 0.3);
    transform: scale(1.05);
    outline: none;
  }
  
  /* Eye Icon */
  .eye-icon {
    position: absolute;
    right: 10px;
    top:59%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #fff;
    font-size: 20px;
  }
  
  /* Login Button */
  .btn-send {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    background: linear-gradient(135deg, #ff6f61, #ff3b2f);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .btn-send:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(255, 111, 97, 0.4);
  }
  
  /* Captcha Container */
  .captcha-container {
    margin-top: 10px;
    position: absolute;
    top: 1vw;
    right: 1vw;
    filter: invert(1);
    height: 11vw;
    display: flex;
    justify-content: center;
  }
  
  /* Policy Links */
  .mob-inform-link {
    list-style: none;
    padding: 0;
    margin-top: 20px;
    text-align: center;
  }
  
  .mob-inform-link li {
    display: inline-block;
    margin: 0 10px;
  }
  
  .mob-inform-link a {
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  .mob-inform-link a:hover {
    color: #ff6f61;
    text-decoration: underline;
  }